
@use "client/css/globals" as *;

::v-deep textarea {
    width: 590px; // magic number the same width as input
}

.modal-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.institution-img {
    border-radius: 5px;
}
