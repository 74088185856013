
@use "client/css/globals" as *;

.step-header {
    padding-bottom: $spacingSmall;
}

.document-options {
    display: flex;
    flex-direction: column;

    .document-option {
        background: $brandColorPrimaryContrast;
        border: 1px solid $borderColorLight;
        border-bottom: none;
        display: flex;
        height: 44px;
        width: 100%;

        &:first-child {
            border-radius: 5px 5px 0 0;
        }

        &:last-child {
            border-bottom: 1px solid $borderColorLight;
            border-radius: 0 0 5px 5px;
        }

        .document-option-label {
            align-content: center;
            height: 100%;
            padding-left: 10px;
            width: 100%;

            .radio-button {
                accent-color: $pilotPurple;
            }
        }
    }
}

.step-2-container {
    .step-2-header {
        background-color: $backgroundColorPrimary;
        margin-bottom: 10px;
        padding: $spacingMedium $spacingMedium;
    }

    .step-2-content {
        background-color: $backgroundColorPrimary;
        padding: $spacingMedium $spacingMedium;

        &::v-deep .flatpickr-calendar {
            // This causes the calendar popup to
            // - match the width of the input fields
            // - grow and shrink with the input fields
            width: auto;
        }

        .date-picker-wrapper {
            display: flex;
            gap: 10px;
            justify-content: center;

            .month-year-picker-container {
                flex: auto;
                padding-top: $spacingSmall;

                &::v-deep .flatpickr-wrapper {
                    width: 100%;
                }
            }

            &::v-deep .flatpickr-monthSelect-month {
                margin: 0;
            }

            .to-text {
                align-content: end;
                margin-bottom: 10px;
            }
        }

        .calendar-popup-padding {
            height: 250px;
        }
    }
}
